import script from "./ResetPassword.vue?vue&type=script&setup=true&lang=ts"
export * from "./ResetPassword.vue?vue&type=script&setup=true&lang=ts"

import "./ResetPassword.vue?vue&type=style&index=0&id=1e74ec5a&lang=scss"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QForm,QInput,QBtn,QSpinnerBars,QCardSection});
